import { createAction } from '@reduxjs/toolkit'
import i18n from 'i18next'
import _ from 'underscore'

import MirdicClient from '../clients/MirdicClient'
import {
  DELIVERY_TYPE_ROOM,
  DELIVERY_TYPE_TABLE,
  PAY_LATER,
  PRODUCT_UNAVAILABLE,
  TOTAL_PRICE_MISMATCH,
  errorHandlerIgnoredErrorCodes,
  errorHandlerIgnoredUrls
} from '../common/constants'
import { getBackgroundImageUrl } from '../common/hotels'
import { ROUTES, getHotelCode, isRoute } from '../common/routes'
import { GlobalToastActions } from '../components/layout/GlobalToast/GlobalToast'
import { getEnvBasedConfig } from '../config/config'
import {
  findFirstSalePointWithSelectedProduct,
  getAllSalePoints,
  getOrderInformation,
  getOrderRequest,
  getOrderToken,
  getSelectedProducts,
  getShoppingCartWarnings,
  hasSelectedProducts
} from '../selectors/selectors'

export const signedIn = createAction('USER/SIGNED_IN')
export const signedOut = createAction('USER/SIGNED_OUT')
export const receivedProfile = createAction('USER/RECEIVED_PROFILE')
export const receivedStoredPaymentMethods = createAction('USER/RECEIVED_STORED_PAYMENT_METHOD')
export const deleteStoredPaymentMethod = createAction('USER/DELETE_STORED_PAYMENT_METHOD')

export const ssoRendered = createAction('SSO/RENDERED')
export const ssoOpened = createAction('SSO/OPENED')
export const ssoClosed = createAction('SSO/CLOSED')
export const ssoErrorOccurred = createAction('SSO/ERROR_OCCURRED')

export const receivedSalePoints = createAction('SALES_POINTS/RECEIVED')
export const updateSalePoint = createAction('SALES_POINTS/UPDATE_SALE_POINT')
export const addProduct = createAction('SALES_POINTS/ADD_PRODUCT')
export const removeProduct = createAction('SALES_POINTS/REMOVE_PRODUCT')
export const removeAllProducts = createAction('SALES_POINTS/REMOVE_ALL_PRODUCTS')
export const eraseProduct = createAction('SALES_POINTS/ERASE_PRODUCT')
export const restoreProducts = createAction('SALES_POINTS/RESTORE_PRODUCTS')

export const requestSummary = createAction('SUMMARY/REQUEST/START')
export const receivedSummary = createAction('SUMMARY/RECEIVED')
export const clearSummary = createAction('SUMMARY/CLEAR')
export const postOrderRequest = createAction('ORDER_REQUEST/START')

export const receivedOrderToken = createAction('ORDER/RECEIVED_TOKEN')
export const clearOrderToken = createAction('ORDER/CLEAR_TOKEN')
export const setDeliveryDestinationNumber = createAction('ORDER/SET_DELIVERY_DESTINATION_NUMBER')
export const setDeliveryType = createAction('ORDER/SET_DELIVERY_TYPE')
export const setPayNowOrLater = createAction('ORDER_REQUEST/SET_PAY_NOW_OR_LATER')
export const setOrderRequest = createAction('ORDER_REQUEST/SET')
export const resetOrderRequest = createAction('ORDER_REQUEST/RESET')
export const resetOrderRequestForLoggedInUser = createAction('ORDER_REQUEST/RESET_FOR_LOGGED_IN_USER')

export const receivedOrderInformation = createAction('ORDER_INFORMATION/RECEIVED')
export const setApiError = createAction('API/ERROR')
export const clearApiError = createAction('API/ERROR_CLEAR')

export const toggleSidebar = createAction('LAYOUT/TOGGLE_SIDEBAR')
export const setBackgroundImageUrl = createAction('LAYOUT/SET_BACKGROUND_IMAGE_URL')

export const initApp = (history, hotelCode) => async (dispatch, getState) => {
  const state = getState()
  const backgroundImageUrl = getBackgroundImageUrl(hotelCode)

  dispatch(setBackgroundImageUrl(backgroundImageUrl))

  initMirdicClientIfNeeded(dispatch)

  const searchParams = new URLSearchParams(history.location.search)
  const orderToken = getOrderToken(state)
  const orderInformation = getOrderInformation(state)
  const deliveryTypeCandidate = searchParams.get('deliveryType') || orderInformation?.header.deliveryType
  if ([DELIVERY_TYPE_ROOM, DELIVERY_TYPE_TABLE].includes(deliveryTypeCandidate)) {
    dispatch(setDeliveryType(deliveryTypeCandidate))
  } else {
    dispatch(setDeliveryType(DELIVERY_TYPE_ROOM))
  }

  const deliveryDestinationNumberCandidate =
    searchParams.get('deliveryNumber') || orderInformation?.header.destinationNumber
  if (deliveryDestinationNumberCandidate) dispatch(setDeliveryDestinationNumber(deliveryDestinationNumberCandidate))

  const salePoints = await MirdicClient.getHotelSalePoints(hotelCode)
  dispatch(receivedSalePoints(salePoints))

  if (orderToken && orderInformation) {
    const {
      guest: { firstName, lastName, email },
      header: { destinationNumber, salePoint },
      items,
      comment
    } = orderInformation

    dispatch(restoreProducts({ orderedItems: items, salePointCode: salePoint.code }))
    dispatch(setOrderRequest({ deliveryDestinationNumber: destinationNumber, firstName, lastName, email, comment }))

    dispatch(clearOrderToken())
  }
}

export const changeLanguage = ({ language, history }) => async dispatch => {
  i18n.changeLanguage(language)
  const { location } = history
  const search = new URLSearchParams(location.search)
  search.set('locale', language)
  const url = `${location.pathname}?${search.toString()}${location.hash}`
  history.replace(url)

  MirdicClient.initLocale(language)

  const salePoints = await MirdicClient.getHotelSalePoints(getHotelCode(history))
  dispatch(receivedSalePoints(salePoints))
}

export const handleDeleteStoredPaymentMethod = storedPaymentMethodId => async dispatch => {
  await MirdicClient.deleteStoredPaymentMethod(storedPaymentMethodId)

  dispatch(deleteStoredPaymentMethod(storedPaymentMethodId))
}

export const initConfirmationPage = orderToken => async dispatch => {
  initMirdicClientIfNeeded(dispatch)

  const order = await MirdicClient.getOrder(orderToken)
  const { summary, header, hotelCode } = order
  const backgroundImageUrl = getBackgroundImageUrl(hotelCode)

  dispatch(setBackgroundImageUrl(backgroundImageUrl))
  dispatch(receivedOrderInformation({ ...summary, salePointCode: header.salePoint.code, hotelCode }))
}

export const initPaymentRejectedPage = (history, orderToken) => async dispatch => {
  initMirdicClientIfNeeded(dispatch)

  dispatch(receivedOrderToken(orderToken))

  const orderInformation = await MirdicClient.getOrder(orderToken)
  dispatch(receivedOrderInformation(orderInformation))

  dispatch(initApp(history, orderInformation.hotelCode))
}

export const getUserProfile = () => async (dispatch, getState) => {
  const { user } = getState()
  const profile = await MirdicClient.getUserProfileBySsoToken(user.token)
  dispatch(receivedProfile(profile))
}

export const getSummary = forceUpdate => async (dispatch, getState) => {
  const state = getState()
  const { totalPrice, lastRequestPayload } = state.summary

  if (!hasSelectedProducts(state)) {
    MirdicClient.abortPostSummary()
    if (totalPrice) dispatch(clearSummary())
    return
  }

  const requestPayload = createSummaryRequestPayload(state)
  const nothingImportantChanged = _.isEqual(requestPayload, lastRequestPayload)
  if (nothingImportantChanged && !forceUpdate) return

  dispatch(requestSummary(requestPayload))
  const response = await MirdicClient.postSummary(requestPayload)

  if (!response) return
  dispatch(receivedSummary(response))
}

export const getStoredPaymentMethods = (token, channelMerchant) => async dispatch => {
  const storedPaymentMethods = await MirdicClient.getStoredPaymentMethods(token, channelMerchant)
  dispatch(receivedStoredPaymentMethods(storedPaymentMethods))
}

export const createOrder = () => async (dispatch, getState) => {
  const state = getState()
  const summaryRequest = createSummaryRequestPayload(state)

  const { totalPrice } = state.summary
  const phone = state.user?.phone
  const {
    firstName,
    lastName,
    email,
    comment,
    deliveryDestinationNumber,
    deliveryDateTime,
    payNowOrLater
  } = state.orderRequest

  if (getShoppingCartWarnings(getState()).length > 0) return

  const requestPayload = {
    approvedTotalPrice: totalPrice,
    firstName,
    lastName,
    email,
    phone,
    comment,
    destinationNumber: deliveryDestinationNumber,
    deliveryDateTime,
    isPayLaterRequested: payNowOrLater === PAY_LATER,
    shoppingCart: summaryRequest
  }

  const { orderToken } = state
  const { storedPaymentMethodId } = getOrderRequest(state)

  if (orderToken) {
    redirectToPaymentPage(orderToken, storedPaymentMethodId)
  } else {
    dispatch(postOrderRequest())

    try {
      const response = await MirdicClient.createOrder(requestPayload)

      redirectToPaymentPage(response.orderToken, storedPaymentMethodId)
    } catch ({ errorCode }) {
      if ([TOTAL_PRICE_MISMATCH, PRODUCT_UNAVAILABLE].includes(errorCode)) return dispatch(getSummary(true))
    }
  }
}

const redirectToPaymentPage = async (orderToken, storedPaymentMethodId) => {
  const paymentPageUrl = await MirdicClient.getPaymentPageUrl(orderToken, storedPaymentMethodId)
  window.location.assign(paymentPageUrl.redirectLink)
}

export const handleApiError = error => dispatch => {
  dispatch(setApiError(error))
  const { errorCode, status, url } = error

  if (
    !errorHandlerIgnoredErrorCodes.includes(errorCode) &&
    !errorHandlerIgnoredUrls.some(ignoredUrl => url.includes(ignoredUrl))
  ) {
    GlobalToastActions.show({
      children: i18n.t(`bottomToast.error.${status}`, i18n.t('bottomToast.error.default')),
      type: 'warning',
      closeButtonText: i18n.t('bottomToast.error.dismiss'),
      onClose: () => dispatch(clearApiError())
    })
  }
}

const HANDLE_SIGN_IN_RETRY = {
  COUNT: 0,
  MAX_COUNT: 50,
  TIMEOUT: 100
}

export const handleSignIn = ({ token, userId, history }) => async (dispatch, getState) => {
  dispatch(signedIn({ token, userId }))
  MirdicClient.initSsoToken(token)

  if (getAllSalePoints(getState()).length === 0 && HANDLE_SIGN_IN_RETRY.COUNT < HANDLE_SIGN_IN_RETRY.MAX_COUNT) {
    HANDLE_SIGN_IN_RETRY.COUNT += 1
    return setTimeout(() => dispatch(handleSignIn({ token, userId, history })), HANDLE_SIGN_IN_RETRY.TIMEOUT)
  }

  if (getAllSalePoints(getState()).length) {
    const hotelCode = isRoute(history, ROUTES.PAYMENT_REJECTED_PAGE)
      ? getOrderInformation(getState())?.hotelCode
      : getHotelCode(history)

    if (hotelCode) {
      const salePoints = await MirdicClient.getHotelSalePoints(hotelCode)
      const channelMerchant = salePoints?.[0]?.hotelPaymentMerchant
      dispatch(receivedSalePoints(salePoints))
      dispatch(getStoredPaymentMethods(token, channelMerchant))
    }
  }

  return dispatch(getSummary(true))
}

export const handleSignedOut = ({ history }) => async (dispatch, getState) => {
  dispatch(signedOut())
  MirdicClient.resetSsoToken()

  if (getAllSalePoints(getState()).length) {
    const salePoints = await MirdicClient.getHotelSalePoints(getHotelCode(history))
    dispatch(receivedSalePoints(salePoints))
  }

  return dispatch(getSummary(true))
}

function initMirdicClientIfNeeded(dispatch) {
  if (!MirdicClient.isInitialized()) {
    MirdicClient.init({
      errorHandler: error => dispatch(handleApiError(error)),
      locale: i18n.language,
      url: getEnvBasedConfig().mirdicUrl,
      paymentServiceUrl: getEnvBasedConfig().paymentServiceUrl
    })
  }
}

function createSummaryRequestPayload(state) {
  return {
    products: getSelectedProducts(state).map(({ productId, count }) => ({ productId, quantity: count })),
    salePointId: findFirstSalePointWithSelectedProduct(state).salePointId,
    deliveryType: getOrderRequest(state).deliveryType
  }
}
